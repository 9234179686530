import axios from 'axios';

export default {
  name: 'app-global-error-snack',
  data: function () {
    return {
      visible: false,
      text: ''
    };
  },
  methods: {
    $_logoutUser: function () {
      this.$store.commit('removeUser');
      this.$router.push({ name: 'UserLogin' });
    },
    $_showErrorSnack: function (error) {
      if (error.response.status === 400) {
        this.text = `Ошибка валидации. Проверьте корректность указанных данных`;
      } else {
        this.text = `Ошибка: ${error.response.status}. ${error.response.data.message}`;
      }

      this.visible = true;
    }
  },
  created: function () {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          this.$_logoutUser();
        } else {
          this.$_showErrorSnack(error);
        }

        return Promise.reject(error);
      });
  }
};