import _ from 'lodash';
import axios from 'axios';
import { orderPaymentMethods, subscriptionStatuses } from '../../../../js/status-mapper';

export default {
  name: 'subscription-list',
  data: function () {
    return {
      fab: false,
      subscriptions: [],
      isLoading: true,
      headers: [
        {text: '№', value: 'id', sortable: true},
        {text: 'Пользователь', value: 'user', sortable: false},
        {text: 'Тариф', value: 'plan', sortable: true},
        {text: 'Дата начала', value: 'timeSubscribed', sortable: true},
        {text: 'Дата окончания', value: 'timeExpiring', sortable: true},
        {text: 'Статус', value: 'status', align: 'center', sortable: false}
      ],
      options: {
        sortBy: ['id'],
        sortDesc: [false]
      },
      footerProps: {
        itemsPerPageText: 'Подписок на странице',
        itemsPerPageAllText: 'Все',
        itemsPerPageOptions: [10, 25, -1]
      },
    };
  },
  methods: {
    subscriptionClick: function(subscription) {
      this.$router.push({ name: 'SubscriptionDetails', params: { id: subscription.id }});
    },
    sortFilter: function (items, index, isDescending) {
      const sortBy = index[0];
      return _.orderBy(items, [sortBy], [isDescending[0] ? 'desc' : 'asc']);
    },
    $_fetchSubscriptions: function () {
      this.isLoading = true;

      axios.get('/api/v2/subscriptions')
        .then(response => {
          this.subscriptions = response.data.map(subscription => this.$_mapSubscription(subscription));
          this.isLoading = false;
        });
    },
    $_mapSubscription: function (subscription) {
      return {
        id: subscription.id,
        user: subscription.user,
        plan: subscription.plan.publicName,
        timeSubscribed: subscription.timeSubscribed,
        timeExpiring: subscription.timeExpiring,
        paymentMethod: subscription.paymentMethod ? orderPaymentMethods[subscription.paymentMethod].text : null,
        status: subscriptionStatuses[subscription.status]
      }
    }
  },
  mounted: function () {
    this.$_fetchSubscriptions();
  }
};