import moment from 'moment';
import axios from 'axios';
import { orderPaymentMethods, subscriptionStatuses } from '../../../../js/status-mapper';

import AppDatePicker from '../../../common/date-picker/AppDatePicker';

export default {
  name: 'subscription-details',
  components: {
    AppDatePicker
  },
  data: function () {
    return {
      subscriptionId: Number(this.$route.params.id),
      subscription: null,
      subscriptionStatus: null,
      paymentMethod: null,
      fetchedPlaces: [],
      placesError: '',
      showPlaceList: false,
      confirmDialog: false,
      selectedPlace: null,
	  exclusiveForm: {
		  show: false
	  },
	  prolongExclusiveRequest: {
		  exclusiveEnd: null
	  }
    };
  },
  methods: {
      async fetchPlaces() {
          try {
              const response = await fetch(`/api/v2/users/${this.subscription.user.id}/places?excludeSubscription=${this.subscriptionId}`);
              if (!response.ok) throw new Error('Network response was not ok');
              const data = await response.json();
              if (data.length === 0) {
                  this.placesError = 'Немає локацій для додавання';
              } else {
                  this.fetchedPlaces = data;
                  this.placesError = '';
              }
          } catch (error) {
              this.placesError = 'Error fetching places';
          }
      },
      async subscribePlace(place) {
          try {
              const response = await fetch(`/api/v2/subscriptions/${this.subscriptionId}/add`, {
                  method: 'PUT',
                  body: JSON.stringify({placeId: place.id, userId: this.subscription.user.id}),
                  headers: {
                      'Content-Type': 'application/json',
                  },
              });
              if (response.ok) {
                  this.$notify({type: 'success', message: `Приміщення "${place.name}" успішно додано!`});
                  this.fetchedPlaces = [];
                  this.$_fetchSubscription();
                  await this.togglePlaceList();
              } else {
                  throw new Error('Network response was not ok');
              }
          } catch (error) {
              this.$notify({type: 'error', message: 'Сталася помилка при спробі додати приміщення в підписку'});
          }
      },
      async togglePlaceList() {
          this.showPlaceList = !this.showPlaceList;
          if (this.showPlaceList) {
              await this.fetchPlaces();
          } else {
              this.fetchedPlaces = [];
              this.placesError = '';
          }
      },
      showConfirmation(place) {
          this.selectedPlace = place;
          this.confirmDialog = true;
      },
      async confirmSubscription() {
          if (this.selectedPlace) {
              await this.subscribePlace(this.selectedPlace);
          }
          this.confirmDialog = false;
          this.selectedPlace = null;
      },
	showExclusiveForm: function () {
		this.exclusiveForm.show = true;
	},
	closeExclusiveForm: function () {
		this.exclusiveForm.show = false;
		this.prolongExclusiveRequest.exclusiveEnd = null;
	},
	prolongExclusive: function () {
		const request = {
			exclusiveEnd: moment.unix(this.prolongExclusiveRequest.exclusiveEnd).format('YYYY-MM-DD')
		};

		axios.put(`/api/v2/subscriptions/${this.subscriptionId}`, request)
			.then(response => {
			    this.$_fetchSubscription();
				this.closeExclusiveForm();
		});
	},
    $_fetchSubscription: function () {
      axios.get(`/api/v2/subscriptions/${this.subscriptionId}`)
        .then(response => {
          this.subscription = response.data;
          this.subscriptionStatus = subscriptionStatuses[this.subscription.status];
          this.paymentMethod = orderPaymentMethods[this.subscription.paymentMethod];
        });
    }
  },
  mounted: function () {
    this.$_fetchSubscription();
  }
};