export default {
  name: 'app-navigation',
  data: function () {
    return {
      items: [
        {
          icon: 'mdi-account-multiple',
          text: 'Пользователи',
          to: {name: 'UserList'}
        },
        {
          icon: 'mdi-map-marker',
          text: 'Помещения',
          to: {name: 'PlacesList'}
        },
        {
          icon: 'mdi-picture-in-picture-top-right',
          text: 'Лендинги',
          to: {name: 'LandingList'}
        },
        {
          icon: 'mdi-briefcase-plus-outline',
          text: 'Подписки',
          to: {name: 'SubscriptionList'}
        },
        {
          icon: 'mdi-star',
          text: 'Отзывы',
          to: { name: 'ReviewList' }
        }
      ],
    }
  },
  computed: {
    drawer: {
      get: function () {
        return this.$store.state.global.drawer;
      },
      set: function (ignored) {}
    },
    domainUrl: function () {
      return this.$store.state.settings.domainUrl;
    }
  }
}