import Vue from 'vue'
import Router from 'vue-router'
import MainDashboard from '../components/dashboards/main-dashboard/MainDashboard'

import UserLogin from '../components/system/login/UserLogin'
import UserList from '../components/users/users-list/UserList'
import UserDetails from '../components/users/details/UserDetails'

import PlacesList from '../components/places/places-list/PlacesList'
import PlaceDetails from '../components/places/details/PlaceDetails'
import PlaceEdit from '../components/places/edit/PlaceEdit'

import SubscriptionList from '../components/users/subscription/list/SubscriptionList'
import SubscriptionDetails from '../components/users/subscription/details/SubscriptionDetails'
import SubscriptionCreate from '../components/users/subscription/create/SubscriptionCreate'

import InternationalizationList from '../components/internationalization/InternationalizationList'
import ReviewList from '../components/reviews/ReviewList'
import AmenityList from '../components/amenities/AmenityList'
import EventTypeList from '../components/place-properties/event-type/EventTypeList'

import LanguageDictionary from '../components/internationalization/LanguageDictionary'

import LandingList from '../components/landing/landing-list/LandingList'
import LandingEdit from '../components/landing/edit/LandingEdit'

import UrlFormatting from '../components/utils/url-formatting/UrlFormatting'

import store from '../store'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'MainDashboard',
      component: MainDashboard,
      meta: {
        title: 'Основной дашборд'
      }
    },
    {
      path: '/login',
      name: 'UserLogin',
      component: UserLogin,
      meta: {
        title: 'Вход'
      }
    },
    {
      path: '/users',
      name: 'UserList',
      component: UserList,
      meta: {
        title: 'Список пользователей'
      }
    },
    {
      path: '/users/:id',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'UserDetails',
      component: UserDetails,
      meta: {
        title: 'Пользователь'
      }
    },
    {
      path: '/places',
      name: 'PlacesList',
      component: PlacesList,
      meta: {
        title: 'Список помещений'
      }
    },
    {
      path: '/places/:id',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'PlaceDetails',
      component: PlaceDetails,
      meta: {
        title: 'Помещение'
      }
    },
    {
      path: '/places/:id/edit',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'PlaceEdit',
      component: PlaceEdit,
      meta: {
        title: 'Редактирование помещения'
      }
    },
    {
      path: '/subscriptions',
      name: 'SubscriptionList',
      component: SubscriptionList,
      meta: {
        title: 'Список подписок'
      }
    },
    {
      path: '/reviews',
      name: 'ReviewList',
      component: ReviewList,
      meta: {
        title: 'Отзывы'
      }
    },
    {
      path: '/amenities',
      name: 'AmenityList',
      component: AmenityList,
      meta: {
        title: 'Услуги'
      }
    },
    {
      path: '/event-types',
      name: 'EventTypeList',
      component: EventTypeList,
      meta: {
        title: 'Мероприятия'
      }
    },
    {
      path: '/internationalizations',
      name: 'InternationalizationList',
      component: InternationalizationList,
      meta: {
        title: 'Список словарей'
      }
    },
    {
      path: '/internationalizations/:lang',
      props: (route) => ({ lang: route.params.lang }),
      name: 'LanguageDictionary',
      component: LanguageDictionary,
      meta: {
        title: 'Словарь языка'
      }
    },
    {
      path: '/subscriptions/create',
      name: 'SubscriptionCreate',
      component: SubscriptionCreate,
      meta: {
        title: 'Добавление подписки'
      }
    },
    {
      path: '/subscriptions/:id',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'SubscriptionDetails',
      component: SubscriptionDetails,
      meta: {
        title: 'Подписка'
      }
    },
    {
      path: '/landings',
      name: 'LandingList',
      component: LandingList,
      meta: {
        title: 'Список лендингов'
      }
    },
    {
      path: '/landings/:id',
      props: (route) => ({ id: Number(route.params.id) }),
      name: 'LandingEdit',
      component: LandingEdit,
      meta: {
        title: 'Редактирование лендинга'
      }
    },
    {
      path: '/utils/url-formatting',
      name: 'UrlFormatting',
      component: UrlFormatting,
      meta: {
        title: 'Формирование ссылки'
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  }).forEach(tag => document.head.appendChild(tag));

  next();
});

router.beforeResolve((to, from, next) => {
  if (!store.state.user.isAuthenticated && to.name !== 'UserLogin') {
    store.dispatch('fetchUser').then(() => {
      next();
    }, () => {
      store.commit('keepReferer', to.fullPath);
      next({ name: 'UserLogin' });
    });
  } else {
    next();
  }
});

export default router;
