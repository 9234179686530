import _ from 'lodash';
import axios from 'axios';
import { subscriptionStatuses } from '../../../../../js/status-mapper';

export default {
  name: 'user-details-subscriptions-tab',
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      subscriptions: [],
      isLoading: true,
      footerProps: {
        itemsPerPageText: 'Подписок на странице',
        itemsPerPageOptions: [10, 25, 50]
      },
      headers: [
        {text: 'Тариф', value: 'plan', sortable: true},
        {text: 'Дата начала', value: 'timeSubscribed', sortable: true},
        {text: 'Дата окончания', value: 'timeExpiring', sortable: true},
        {text: 'Статус', value: 'status', align: 'center', sortable: false}
      ],
      options: {
        sortBy: ['timeSubscribed'],
        sortDesc: [true]
      }
    };
  },
  methods: {
    subscriptionClick: function(subscription) {
      this.$router.push({ name: 'SubscriptionDetails', params: { id: subscription.id }});
    },
    $_fetchSubscriptions: function () {
      this.isLoading = true;

      axios.get(`/api/v2/users/${this.userId}/subscriptions`)
        .then(response => {
          this.subscriptions = response.data.map(subscription => this.$_mapSubscription(subscription));
          this.isLoading = false;
        });
    },
    $_mapSubscription: function (subscription) {
      return {
        id: subscription.id,
        plan: subscription.plan.publicName,
        timeSubscribed: subscription.timeSubscribed,
        timeExpiring: subscription.timeExpiring,
        status: subscriptionStatuses[subscription.status]
      }
    }
  },
  created: function () {
    this.$_fetchSubscriptions();
  }
};