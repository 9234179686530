import _ from 'lodash'
import axios from 'axios';
import { mapState } from 'vuex';
import draggable from 'vuedraggable'
import Vue from 'vue'
import moment from 'moment'

import { placeStatuses, placeTypes, pricingModel } from '../../../js/status-mapper'
import upload from '../../../js/upload'
import { googleLoaderComponent } from '../../../js/google-loader-mixin'

function generatePageContent() {
  // cleaning time values
  const cleaningTimeSelectValue = _.range(1, 13).map(value => {
    return {
      text: `${value} час.`,
      value: `${moment().hour(value).minute(0).format('HH:mm')}`
    }
  });
  cleaningTimeSelectValue.unshift({text: '30 минут', value: '00:30'});

  // preparation time
  const preparationTimeSelectValue = _.range(1, 24).map(value => {
    return {
      text: `${value} час.`,
      value: `${moment().hour(value).minute(0).format('HH:mm')}`
    }
  });
  preparationTimeSelectValue.unshift({text: '30 минут', value: '00:30'});

  // days
  const days = [ { value: 1, text: 'ПН', textFull: 'Понедельник' }, { value: 2, text: 'ВТ', textFull: 'Вторник' }, { value: 3, text: 'СР', textFull: 'Среда' }, { value: 4, text: 'ЧТ', textFull: 'Четверг' }, { value: 5, text: 'ПТ', textFull: 'Пятница' }, { value: 6, text: 'СБ', textFull: 'Суббота' }, { value: 7, text: 'ВС', textFull: 'Воскресенье' } ];

  const timeOptions = {
    start: '00:00',
    end: '23:30',
    step: 30
  };

  let currentTime = timeOptions.start;
  const periodTime = [currentTime];
  while(true) {
    currentTime = moment(currentTime, 'HH:mm')
      .date(1)
      .add(timeOptions.step, 'minutes')
      .format('HH:mm');
    periodTime.push(currentTime);
    if (currentTime === timeOptions.end) {
      break;
    }
  }

  return {
    cleaningTimeSelectValue: cleaningTimeSelectValue,
    preparationTimeSelectValue: preparationTimeSelectValue,
    days: days,
    periodTime: periodTime,
    placeTypes:  Object.values(placeTypes)
  }
}

export default {
  name: 'place-edit',
  components: {
    draggable
  },
  mixins: [googleLoaderComponent()],
  data: function () {
    return {
      placeTypes: placeTypes,
      activeTab: null,
      pricingModels: Object.values(pricingModel),
      pageContent: generatePageContent(),
      placeId: Number(this.$route.params.id),
      moderation: this.$route.query.moderation,
      place: null,
      properties: this.$store.state.properties,
      availableLocales: this.$store.state.availableLocales,
      uploading: false,
      autocomplete: null,
      googleAddress: null,
      snackVisible: false,
      savedLocale: null,
      placePhotos: [],
      updatePlaceObject: {
        placeId: null,
        name: '',
        title: null,
        address: {
          cityId: null,
          districtId: null,
          subDistrictId: null,
          formattedName: null,
          lat: null,
          lng: null
        },
        type: null,
        eventTypes: [],
        totalArea: null,
        totalCapacity: null,
        placementTypes: [],
        amenities: [],
        photos: [],
        scheduleInfo: {
          pricingModel: 'RENT',
          description: null,
          minRentValue: null,
          preparationTime: null,
          cleaningTime: null,
          cleaningPrice: null,
          discountValue: null,
          discountAmount: null,
          periods: []
        },
        description: '',
        rules: null
      },
      scheduleModel: {
        days: [],
        startTime: null,
        endTime: null,
        price: null
      },
      photoStudioInfo: {
        styleIds: [],
        snapTypeIds: [],
        equipmentIds: []
      },
      cafeInfo: {
        kitchenTypeIds: []
      }
    }
  },
  watch: {
    placePhotos: {
      deep: true,
      handler: function (value) {
        this.updatePlaceObject.photos = value
          .filter(photo => photo.url)
          .map(photo => photo.id);
      }
    },
    locale(val, oldVal) {
      this.$_fetchPlace();
      this.$store.dispatch('fetchUser');
    }
  },
  computed: {
    supportsExtensions: function () {
      const extensionsSupportedTypes = [ placeTypes.CAFE.value, placeTypes.PHOTO_STUDIO.value ];
      return extensionsSupportedTypes.includes(this.updatePlaceObject.type);
    },
    ...mapState(['locale']),
    computedLocaleState() {
      return this.locale;
    }
  },
  methods: {
    openFileExplorer: function () {
      this.$refs.fileInput.click();
    },
    deleteMedia: function(mediaId) {
      if (this.placePhotos.length === 1) return;

      const mediaIndex = _.findIndex(this.placePhotos, ['id', mediaId]);
      if (mediaIndex >= 0) {
        this.placePhotos.splice(mediaIndex, 1);
      }
    },
    uploadMedia: function(files) {
      this.uploading = true;
      const image = files[0];

      upload(`/api/v2/places/${this.placeId}/medias`, image)
        .then(response => {
          this.uploading = false;
          this.placePhotos.push(response.data);
        })
        .catch(() => {
          this.uploading = false;
        });
    },
    savePlace: function () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          const updatePlaceObject = Object.assign({}, this.updatePlaceObject);
          if (result) {
            switch (updatePlaceObject.type) {
              case placeTypes.PHOTO_STUDIO.value:
                updatePlaceObject.photoStudioInfo = this.photoStudioInfo;
                break;
              case placeTypes.CAFE.value:
                updatePlaceObject.cafeInfo = this.cafeInfo;
                break;
            }

            axios.put(`/api/v2/places/${this.placeId}`, updatePlaceObject, {
              params: {
                moderation: this.moderation
              }
            }).then(() => {
              this.snackVisible = true;
              resolve();
            }).catch(() => {
              reject();
            })
          } else {
            reject();
          }
        });
      });
    },
    approvePlace: function () {
      this.savePlace().then(() => {
        axios.put(`/api/v2/places/${this.placeId}/approve`).then(() => {
          this.$router.push({name: 'PlacesList'});
        });
      });
    },
    addSchedule: function () {
      const schedulePeriods = this.updatePlaceObject.scheduleInfo.periods;
      schedulePeriods.push(Object.assign({}, this.scheduleModel));

      this.scheduleModel.days = [];
      this.scheduleModel.startTime = null;
      this.scheduleModel.endTime = null;
      this.scheduleModel.price = null;
    },
    deleteSchedule: function (index) {
      this.updatePlaceObject.scheduleInfo.periods.splice(index, 1);
    },
    formatPeriodDays: function (days) {
      return days.map(day => {
        return this.pageContent.days.find(d => d.value === day).text;
      }).join(', ');
    },
    $_initGooglePlaces: function () {
      const gplacesOptions = {
        types: ['address'],
        componentRestrictions: {country: 'ua'}
      };
      const autocompleteInput = document.getElementById('google-address');

      this.autocomplete = new google.maps.places.Autocomplete(autocompleteInput, gplacesOptions);
      this.autocomplete.addListener('place_changed', () => {
        let gplace = this.autocomplete.getPlace();

        if (gplace && gplace.place_id) {
          const address = this.updatePlaceObject.address;
          address.lat = gplace.geometry.location.lat().toString();
          address.lng = gplace.geometry.location.lng().toString();

          this.googleAddress = gplace.formatted_address;
        }
      });
    },
    $_fetchPlace: function() {
      axios.get(`/api/v2/places/${this.placeId}`, {
        params: {
          moderation: this.moderation
        }
      }).then(response => {
        const place = response.data;
        const availableLocales = this.$store.state.availableLocales;
        const locale = availableLocales.find(l => l.code === place.locale) || availableLocales.find(l => l.code === this.$store.state.locale);
        this.updatePlaceObject = {
          placeId: place.id,
          name: place.name,
          title: place.title,
          locale: locale.code,
          address: {
            formattedName: place.address.formattedName,
            lat: place.address.lat,
            lng: place.address.lng,
            districtId: place.address.district.id,
            subDistrictId: place.address.subDistrict.id,
            cityId: place.address.city.id,
          },
          type: place.type,
          eventTypes: place.eventTypes.filter(e => e).map(e => e.id),
          totalArea: place.area,
          totalCapacity: place.capacity,
          placementTypes: place.placementTypes,
          amenities: place.amenities.map(amenity => {
            return {
              id: amenity.id,
              payable: amenity.payable
            }
          }),
          photos: place.photos.map(photo => photo.id),
          scheduleInfo: place.schedule,
          description: place.description,
          rules: place.rules
        };

        switch (place.type) {
          case placeTypes.PHOTO_STUDIO.value:
            this.photoStudioInfo.styleIds = place.styles.map(style => style.styleType);
            this.photoStudioInfo.snapTypeIds = place.snapTypes.map(snap => snap.snapType);
            this.photoStudioInfo.equipmentIds = place.equipmentList.map(eq => eq.equipmentId);
            break;
          case placeTypes.CAFE.value:
            this.cafeInfo.kitchenTypeIds = place.kitchenTypes.map(type => type.kitchenType);
            break;
        }

        place.freeAmenities = place.amenities.filter(amenity => !amenity.payable);
        place.paidAmenities = place.amenities.filter(amenity => amenity.payable);
        this.placePhotos = place.photos;

        this.moderation = this.moderation || place.status === placeStatuses.NEW.value;
        this.googleAddress = place.address.formattedName;
        this.savedLocale = !!place.locale ? availableLocales.find(l => l.code === place.locale) : null;
        this.place = place;

        this.loadedGoogleScript().then(() => {
          Vue.nextTick(() => {
            this.$_initGooglePlaces();
          });
        });
        this.loadGoogleScript();
      });
    }
  },
  created: function () {
   this.$_fetchPlace();
  }
};