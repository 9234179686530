import axios from 'axios';
import { mapState } from 'vuex';
import { placeStatuses, placeTypes } from '../../../../../js/status-mapper';

export default {
  name: 'user-details-places-tab',
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      headers: [
        {text: '№', value: 'id', sortable: false},
        {text: 'Обложка', value: 'cover', align: 'center', sortable: false, width: '200px'},
        {text: 'Данные', value: 'info', sortable: false},
        {text: 'Тип', value: 'type', align: 'center', sortable: false},
        {text: 'Статус', value: 'status', align: 'center', sortable: false}
      ],
      placesList: [],
      isLoading: true,
    }
  },
  computed: {
    ...mapState(['locale']),
    computedLocaleState() {
      return this.locale;
    }
  },
  watch: {
    locale(val, oldVal) {
      this.$_fetchPlaces();
    },
  },
  methods: {
    placeClick: function (place) {
      this.$router.push({ name: 'PlaceDetails', params: { id: place.id }});
    },
    $_fetchPlaces: function () {
      this.isLoading = true;

      axios.get('/api/v2/places', {
        params: {
          size: 50,
          ownerId: this.userId
        }
      }).then(response => {
          this.placesList = response.data.places.map(view => this.$_mapPlace(view));
          this.isLoading = false;
        });
    },
    $_mapPlace: function (view) {
      return {
        id: view.place.id,
        coverUrl: view.place.photos[0],
        placeName: view.place.name,
        address: view.place.address,
        type: placeTypes[view.place.type],
        status: placeStatuses[view.place.status]
      }
    }
  },
  created: function () {
    this.$_fetchPlaces();
  }
};