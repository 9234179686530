import moment from 'moment/moment';
const ISO_DATE_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'app-date-picker',
  props: {
    initialDate: {
      type: Number,
      required: false,
      default: null
    },
    fieldText: {
      type: String,
      required: true
    },
    startOfDay: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      value: null
    }
  },
  computed: {
    formattedDate: function () {
      if (!this.value) return null;
      return moment(this.value, ISO_DATE_FORMAT).format('DD MMMM YY');
    }
  },
  methods: {
    resetDate: function () {
      this.value = null;
      this.$emit('input', null);
    },
    onDateSelected: function (date) {
      let selectedDate = moment(date, ISO_DATE_FORMAT);
      if (this.startOfDay) {
        selectedDate = selectedDate.startOf('day');
      } else {
        selectedDate = selectedDate.endOf('day');
      }

      this.$emit('input', selectedDate.unix());
    }
  },
  mounted: function () {
    if (!this.initialDate) return;
    this.value = moment.unix(this.initialDate).format(ISO_DATE_FORMAT);
  }
};