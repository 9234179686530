import axios from 'axios'
import { mapState } from 'vuex';
import _ from 'lodash'

import { placeStatuses, placeTypes } from '../../../js/status-mapper'

// One day could be replaced by i18n
const BADGE_MAPPING = new Map([
  ['place.badge.popular', 'Популярное'],
  ['place.badge.recent', 'Новое'],
  ['place.badge.loft_style', 'Стиль лофт']
]);

export default {
  name: 'places-list',
  data: function () {
    return {
      domainUrl: this.$store.state.settings.domainUrl,
      footerProps: {
        itemsPerPageText: 'Помещений на странице',
        itemsPerPageOptions: [10, 25, 50]
      },
      possibleStatuses: Object.values(placeStatuses),
      selectedStatuses: this.$_getStateObject().selectedStatuses || [placeStatuses.ACTIVE.value],
      possibleCities: [],
      selectedCities: this.$_getStateObject().selectedCities || [],
      isLoading: true,
      totalItems: 0,
      headers: [
        {text: '№', value: 'id', sortable: false},
        {text: 'Обложка', value: 'cover', align: 'center', sortable: false, width: '250px'},
        {text: 'Данные', value: 'info', sortable: false},
        {text: 'Хост', value: 'host', sortable: false},
        {text: 'Статус', value: 'status', align: 'center', sortable: false},
        {text: 'Тип', value: 'type', align: 'center', sortable: false},
        {text: 'Ленточки', value: 'badge', align: 'center', sortable: false},
        {text: 'Действия', value: 'actions', align: 'center', sortable: false}
      ],
      placesList: [],
      options: this.$_getStateObject().options || {
        sortBy: 'id',
        descending: true
      },
      searchValue: this.$_getStateObject().searchValue || '',
      badgeForm: {
        show: false,
        place: null,
        values: [],
        possibleValues: this.$store.state.properties.badges
      },
      moderation: this.$_getStateObject().moderation,
      subscribed: this.$_getStateObject().subscribed,
      possibleTypes: Object.values(placeTypes),
      selectedTypes: this.$_getStateObject().selectedTypes || []
    }
  },
  computed: {
    ...mapState(['locale']),
    computedLocaleState() {
      return this.locale;
    }
  },
  watch: {
    options: {
      handler () {
        this.$_fetchPlaces(true);
      },
      deep: true
    },
    locale(val, oldVal) {
      this.$_fetchPlaces(true);
    },
    selectedStatuses: function () {
      this.$_fetchPlaces();
    },
    selectedTypes: function () {
      this.$_fetchPlaces();
    },
    selectedCities: function () {
      this.$_fetchPlaces();
    },
    moderation: function () {
      this.$_fetchPlaces();
    },
    subscribed: function () {
      this.$_fetchPlaces();
    },
    searchValue: _.debounce(function () {
      this.$_fetchPlaces();
    }, 300)
  },
  methods: {
    showBadgeForm: function (place) {
      this.badgeForm.place = place;
      this.badgeForm.show = true;
      if (place.badges && place.badges.length) {
        this.badgeForm.values = place.badges.map(badge => badge.id);
      }
    },
    closeBadgeForm: function () {
      this.badgeForm.show = false;
      this.badgeForm.place = null;
      this.badgeForm.values = [];
    },
    updateBadge: function () {
      const place = _.find(this.placesList, ['id', this.badgeForm.place.id]);
      axios.put(`/api/v2/places/${place.id}/badge`, {
        badgeIds: this.badgeForm.values
      }).then(() => {
        place.badges = this.badgeForm.possibleValues
          .filter(badge => this.badgeForm.values.includes(badge.id));
      });
    },
    getBadgeName: function (badgeNameKey) {
      return BADGE_MAPPING.get(badgeNameKey);
    },
    $_getStateObject: function () {
      return this.$store.state.placesListPage;
    },
    $_fetchPlaces: function (preservePage) {
      this.isLoading = true;
      if (!preservePage) this.options.page = 1;

      axios.get('/api/v2/places', {
        params: {
          statuses: this.selectedStatuses.join(','),
          cities: this.selectedCities.join(','),
          page: this.options.page,
          size: this.options.itemsPerPage,
          nameLike: this.searchValue,
          moderation: this.moderation,
          subscribed: this.subscribed,
          types: this.selectedTypes.join(',')
        }
      })
        .then(response => {
          this.placesList = response.data.places.map(view => this.$_mapPlace(view));
          this.totalItems = response.data.totalPlaces;
          this.isLoading = false;
          this.$_saveState();
        });
    },
    $_saveState: function () {
      const stateObj = {
        selectedStatuses: this.selectedStatuses,
        selectedCities: this.selectedCities,
        options: this.options,
        searchValue: this.searchValue,
        moderation: this.moderation,
        subscribed: this.subscribed,
        selectedTypes: this.selectedTypes
      };
      this.$store.commit('updatePlacesListPage', stateObj);
    },
    $_mapPlace: function (view) {
      return {
        id: view.place.id,
        coverUrl: view.place.photos[0],
        urlSuffix: view.place.urlSuffix,
        placeName: view.place.name,
        address: view.place.address,
        owner: {
          id: view.owner.id,
          name: view.owner.firstName
        },
        status: placeStatuses[view.place.status],
        type: placeTypes[view.place.type],
        badges: view.place.badges,
        isSubscriptionEnabled: view.place.isSubscriptionEnabled
      }
    }
  },
  created: function () {
    this.possibleCities = this.$store.state.properties.cities.map(city => {
      return {
        value: city.id,
        text: city.name
      }
    });
  },
}