import axios from 'axios';

export default {
    name: 'event-type-list',
    data: function () {
        return {
            footerProps: {
                itemsPerPageText: 'Типов мероприятий на странице',
                itemsPerPageOptions: [20, 50, 100]
            },
            isLoading: true,
            headers: [
                { text: 'Имя', value: 'localizedNames', sortable: true },
                { text: ' Cохранить', value: 'save', sortable: false },
            ],
            options: {
                sortBy: 'timeCreated',
                descending: false,
                page: 1
            },
            eventTypes: [],
            snackVisible: false,
        };
    },
    watch: {
        options: {
            handler() {
                this.$_fetchEventTypes(true);
            },
            deep: true
        },
        type: function () {
            this.$_fetchEventTypes();
        },
    },
    methods: {
        saveEventType: function (eventType) {
            axios.put('/api/v2/event-types', eventType)
                .then(response => {
                    let eventTypeIndex = this.eventTypes.findIndex(a => a.id === eventType.id);
                    let updatedeventType = response.data;
                    this.eventTypes[eventTypeIndex] = updatedeventType;
                    this.snackVisible = true;
                })
        },
        touched: function (eventType) {
            let eventTypeIndex = this.eventTypes.findIndex(a => a.id === eventType.id);
            this.eventTypes[eventTypeIndex].touched = true;
        },

        $_fetchEventTypes: function () {
            this.isLoading = true;
            axios.get('/api/v2/event-types', {
                params: {
                    type: this.type,
                }
            }).then(response => {
                this.eventTypes = response.data;
                this.isLoading = false;
            });
        }
    },
    mounted: function () {
        this.$_fetchEventTypes();
    }
};