<template>
	<v-flex xs12 md4 xl3>
		<div class="card card-stats">
			<div class="card-header" :class="[cardClass ? cardClass : 'default']">
				<v-icon color="#fff"
						class="material-icons">
					{{ icon }}
				</v-icon>
			</div>
			<div class="card-content">
				<p class="category">{{ category }}</p>
				<h3 class="title">{{ title }}</h3>
			</div>
			<div class="card-footer">
				<div class="stats">{{ stats }}</div>
			</div>
		</div>
	</v-flex>
</template>

<script>
  export default {
    name: 'main-dashboard-card',
	props: {
      cardClass: {
        type: String
	  },
      icon: {
        type: String,
        required: true
      },
      title: {
        type: Number,
		required: true
	  },
      category: {
        type: String,
        required: true
      },
      stats: {
        type: String,
		default: ""
      }
	}
  };
</script>

<style scoped lang="scss" src="./main-dashboard-card.scss"></style>