export default {
  name: 'app-header',
  data: function() {
    return {
      languages: this.$store.state.availableLocales,
      value: ''
    }
  },
  methods: {
    toggleDrawer: function () {
      this.$store.commit('toggleDrawer');
    },
    updateLanguage: function (locale) {
      this.$cookie.set('lang', locale, 31);
      this.$store.commit('setLocale', locale);
    },
    selectVisibilityChange: function (value) {
      this.isOpen = value;
    }
  },
  mounted: function() {
    let lang = this.$cookie.get('lang');
    if (!!lang) {
      this.value = lang;
      this.$store.commit('setLocale', lang);
    } else {
      lang = 'ru';
      this.$cookie.set('lang', lang, 31);
      this.value = lang;
    }
  }
};