const callbackName = 'vueGoogleMapsInit';

export function googleLoaderComponent () {
  return {
    data: function () {
      return {
        googleScriptId: 'google-map-script'
      }
    },
    methods: {
      loadGoogleScript: function (opts) {
        const existingScript = document.getElementById(this.googleScriptId);
        if (existingScript) {
          window[callbackName]();
          return;
        }

        const options = opts || {
          key: this.$store.state.settings.googleApiKey,
          libraries: 'places',
          v: '3.39',
          language: 'ru',
          region: 'UA',
          callback: callbackName
        };

        let url = 'https://maps.googleapis.com/maps/api/js?' +
          Object.keys(options)
            .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(options[key]))
            .join('&');

        const script = document.createElement('script');
        script.setAttribute('id', this.googleScriptId);
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', url);
        script.setAttribute('async', '');
        script.setAttribute('defer', '');
        document.head.appendChild(script);
      },
      loadedGoogleScript: function () {
        return new Promise((resolve, reject) => {
          window[callbackName] = resolve;
        });
      }
    }
  }
}