import axios from 'axios'
import { mapState } from 'vuex';
import _ from 'lodash'

export default {
  name: 'landing-list',
  data: function () {
    return {
      domainUrl: this.$store.state.settings.domainUrl,
      footerProps: {
        itemsPerPageText: 'Лендингов на странице',
        itemsPerPageOptions: [10, 25, 50]
      },
      possibleCities: [{ value: 0, text: 'Общий'}],
      selectedCities: this.$_getStateObject().selectedCities || [0],
      headers: [
        {text: 'Фильтр', value: 'value'},
        {text: 'Главный текст', value: 'titleText'},
        {text: 'Подзаголовок', value: 'subtitleText'},
        {text: 'Title meta tag', value: 'titleTag'},
        {text: 'Description meta tag', value: 'descriptionTag'},
        {text: 'URL suffix', value: 'urlSuffix'},
        {text: 'Действия', value: 'actions', align: 'center'}
      ],
      options: {
        sortBy: ['value'],
        page: 1
      },
      landingList: [],
      isLoading: true,
      textSearchQuery: '',
      eventTypes: [],
      badgeTypes: []
    }
  },
  computed: {
    ...mapState(['locale']),
    computedLocaleState() {
      return this.locale;
    }
  },
  watch: {
    selectedCities: function () {
      this.$_fetchLandings();
    },
    locale(val, oldVal) {
      this.$_fetchLandings();
    }
  },
  methods: {
    textSearchFilter: function (value, search, landing) {
      if (!search) return true;

      return JSON.stringify(landing).toLowerCase().indexOf(search.toLowerCase()) !== -1;
    },
    $_fetchLandings: function (preservePage) {
      if (!this.selectedCities.length) return;

      if (!preservePage) this.options.page = 1;
      this.isLoading = true;

      axios.get('/api/v2/landings', {
        params: {
          cities: this.selectedCities.join(',')
        }
      }).then(response => {
        this.landingList = response.data.map(rawLanding => {
          const landing = Object.assign({}, rawLanding);
          switch (landing.valueType) {
            case 'EVENT_TYPE':
              landing.value = _.find(this.eventTypes, ['id', landing.valueId]).name;
              break;
            case 'BADGE_TYPE':
              landing.value = _.find(this.badgeTypes, ['id', landing.valueId]).name;
              break;
            case 'CITY_TYPE':
              landing.value = 'Город';
              break;
            case 'PHOTO_STUDIO_TYPE':
              landing.value = 'Фотостудии';
              break;
            case 'CAFE_TYPE':
              landing.value = 'Кафе/бар/ресторан';
              break;
          }

          return landing;
        });
        this.isLoading = false;
        this.$_saveState();
      });
    },
    $_saveState: function () {
      const stateObj = {
        selectedCities: this.selectedCities
      };
      this.$store.commit('updateLandingListPage', stateObj);
    },
    $_getStateObject: function () {
      return this.$store.state.landingListPage;
    }
  },
  created: function () {
    const properties = this.$store.state.properties;
    properties.cities.forEach(city => {
      const cityObj = {
        value: city.id,
        text: city.name
      };
      this.possibleCities.push(cityObj);
    });
    this.eventTypes = _.flatten(Object.values(properties.eventTypes));
    this.badgeTypes = properties.badges;
    this.$_fetchLandings(true);
  }
}