import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';

import AppDatePicker from '../../../common/date-picker/AppDatePicker';

export default {
  name: 'subscription-create',
  components: {
    AppDatePicker
  },
  data: function () {
    return {
      currentStep: 1,
      userSearchQuery: '',
      userStep: {
        users: [],
        selectedUser: [],
        headers: [
          {text: '№', value: 'id'},
          {text: 'Имя', value: 'firstName'},
          {text: 'E-mail', value: 'email'},
          {text: 'Телефон', value: 'phone'}
        ]
      },
      placesStep: {
        places: [],
        selectedPlaces: []
      },
      planStep: {
        subscriptionTerm: null,
        timeSubscribed: null,
        exclusiveTerm: null,
        amount: null
      },
      finishStep: {
        createBtnLoading: false
      }
    };
  },
  computed: {
    selectedUser: function () {
      if (this.userStep.selectedUser.length) {
        const user = this.userStep.selectedUser[0];

        return `${user.firstName} (${user.email})`;
      }

      return '-';
    },
    selectedPlaces: function () {
      return this.placesStep.places.filter(place => this.placesStep.selectedPlaces.includes(place.id));
    }
  },
  watch: {
    userSearchQuery: _.debounce(function () {
      this.$_fetchUsers();
    }, 300),
    'userStep.selectedUser': _.debounce(function (value) {
      if (value.length > 1) {
        this.userStep.selectedUser = [_.last(value)];
      }

      if (this.userStep.selectedUser.length) {
        const userId = this.userStep.selectedUser[0].id;
        this.$_fetchUserPlaces(userId);
      }
    }, 300)
  },
  methods: {
    createSubscription: function () {
      const userId = this.userStep.selectedUser.length ? this.userStep.selectedUser[0].id : undefined;
      const timeSubscribed = this.planStep.timeSubscribed ? moment.unix(this.planStep.timeSubscribed).format('YYYY-MM-DD') : undefined;

      const createSubscriptionRequest = {
        userId: userId,
        placeIds: this.placesStep.selectedPlaces,
        subscriptionTerm: this.planStep.subscriptionTerm,
        timeSubscribed: timeSubscribed,
        exclusiveTerm: this.planStep.exclusiveTerm,
        amount: this.planStep.amount
      };

      this.finishStep.createBtnLoading = true;
      axios.post('/api/v2/subscriptions', createSubscriptionRequest)
        .then(response => {
          this.finishStep.createBtnLoading = false;
          this.$router.push({name: 'SubscriptionDetails', params: {id: response.data.subscriptionId}});
        }).catch(error => {
          this.finishStep.createBtnLoading = false;
      });
    },
    $_fetchUsers: function () {
      axios.get('/api/v2/users', {
        params: {
          type: 'HOST',
          page: 1,
          size: 30,
          searchValue: this.userSearchQuery
        }
      }).then(response => {
        this.userStep.users = response.data.users;
      });
    },
    $_fetchUserPlaces: function (userId) {
      axios.get(`/api/v2/users/${userId}/places`)
        .then(response => {
          this.placesStep.places = response.data;
        });
    }
  }
};