var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-1 default-table",attrs:{"headers":_vm.headers,"items":_vm.subscriptions,"loading":_vm.isLoading,"options":_vm.options,"footer-props":_vm.footerProps,"custom-sort":_vm.sortFilter,"must-sort":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.subscriptionClick},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'UserDetails' , params: { id: item.user.id }}}},[_vm._v(_vm._s(item.user.firstName))])]}},{key:"item.timeSubscribed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.timeSubscribed,'DD.MM.YYYY'))+" ")]}},{key:"item.timeExpiring",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.timeExpiring,'DD.MM.YYYY'))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status.color,"text-color":"white","small":""}},[_vm._v(" "+_vm._s(item.status.text)+" ")])]}}])},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"text-center black--text",attrs:{"value":!_vm.isLoading,"color":"blue-grey lighten-5"}},[_vm._v("Ничего не найдено")])],1)],2)],1)],1),_c('v-speed-dial',{attrs:{"fixed":"","bottom":"","right":"","direction":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","dark":"","fab":"","hover":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","to":{ name: 'SubscriptionCreate' },"color":"green"}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }