import axios from 'axios'

export default {
    name: 'language-dictionary',
    data: function () {
        return {
            link: {},
            path: '',
            selectedElementKey: '',
            selectedElementValue: '',
            dictionary: {}
        }
    },
    methods: {
        itemSelected: function(obj) {
            var rootLink = 'root.';
            this.dictionary[obj.path.substring(rootLink.length)] = 'new';
            this.path = obj.path.substring(rootLink.length);
            this.selectedElementKey = this.path;
            this.link = this.dictionary;
            while(this.path.indexOf('.') != -1) {
                this.link = this.link[this.path.substring(0, this.path.indexOf('.'))];
                this.path = this.path.substring(this.path.indexOf('.') + 1);
            }
            if(this.path.indexOf(']') != -1) {
                this.selectedElementValue = this.link[this.path.substring(0, this.path.length - 3)][Number.parseInt(this.path.substring(this.path.length - 2).substring(0, 1))];
            } else {
                this.selectedElementValue = this.link[this.path];
            }
            delete this.dictionary[obj.path.substring(rootLink.length)];
        },
        change: function() {
            if(this.path.indexOf(']') != -1) {
                this.link[this.path.substring(0, this.path.length - 3)][Number.parseInt(this.path.substring(this.path.length - 2).substring(0, 1))] = this.selectedElementValue;
            } else {
                this.link[this.path] = this.selectedElementValue;
            }
        },
        save: function() {
            this.isLoading = true;
            axios.post('/api/v2/language/' + this.$route.params.lang, this.dictionary)
                .then(response => {
                    this.isLoading = false;
                });
        },
        $_fetchLanguages: function () {
            this.isLoading = true;

            axios.get('/api/v2/language/' + this.$route.params.lang)
                .then(response => {
                    this.dictionary = response.data;
                    this.isLoading = false;
                });
        }
    },
    mounted: function () {
        this.$_fetchLanguages();
    }
};