const placeStatuses = {
  NEW: {
    value: 'NEW',
    text: 'Новое',
    color: 'blue lighten-3'
  },
  ACTIVE: {
    value: 'ACTIVE',
    text: 'Активно',
    color: 'green lighten-1'
  },
  DISABLED: {
    value: 'DISABLED',
    text: 'Скрыто',
    color: 'red accent-2'
  },
  FORCIBLY_DISABLED: {
    value: 'FORCIBLY_DISABLED',
    text: 'Принудительно скрыто',
    color: 'red accent-2'
  },
  DELETED: {
    value: 'DELETED',
    text: 'Удалено',
    color: 'blue-grey darken-1'
  }
};

const placeTypes = {
  VENUE: {
    value: 'VENUE',
    text: 'Для мероприятий',
    color: 'blue lighten-3'
  },
  PHOTO_STUDIO: {
    value: 'PHOTO_STUDIO',
    text: 'Фотостудия',
    color: 'blue-grey lighten-3'
  },
  CAFE: {
    value: 'CAFE',
    text: 'Бар/ресторан',
    color: 'pink lighten-3'
  },
  COTTAGE: {
    value: 'COTTAGE',
    text: 'Загородный дом',
    color: 'green lighten-2'
  }
};

const pricingModel = {
  RENT: {
    value: 'RENT',
    text: 'За время',
    color: 'primary'
  },
  PER_PERSON: {
    value: 'PER_PERSON',
    text: 'За человека',
    color: 'primary'
  }
};

const orderPaymentMethods = {
  LIQPAY: {
    value: 'LIQPAY',
    text: 'LiqPay',
    color: 'blue lighten-3'
  },
  CASH: {
    value: 'CASH',
    text: 'Наличка',
    color: 'blue lighten-3'
  },
  BANK_BILL: {
    value: 'BANK_BILL',
    text: 'По счёту',
    color: 'blue lighten-3'
  }
};

const subscriptionStatuses = {
  ACTIVE: {
    value: 'ACTIVE',
    text: 'Активная',
    color: 'green lighten-1'
  },
  PROLONG: {
    value: 'PROLONG',
    text: 'Продление',
    color: 'blue lighten-3'
  },
  EXPIRED: {
    value: 'EXPIRED',
    text: 'Завершена',
    color: 'blue-grey darken-1'
  }
};

const landingTypes = {
  EVENT_TYPE: {
    value: 'EVENT_TYPE',
    text: 'Тип мероприятия',
    color: 'blue lighten-3'
  },
  BADGE_TYPE: {
    value: 'BADGE_TYPE',
    text: 'Ленточка',
    color: 'blue lighten-3'
  },
  CITY_TYPE: {
    value: 'CITY_TYPE',
    text: 'Город',
    color: 'blue lighten-3'
  },
  PHOTO_STUDIO_TYPE: {
    value: 'PHOTO_STUDIO_TYPE',
    text: 'Фотостудии',
    color: 'blue lighten-3'
  },
  PHOTO_STUDIO_STYLE_TYPE: {
    value: 'PHOTO_STUDIO_STYLE_TYPE',
    text: 'Стиль фотостудии',
    color: 'blue lighten-3'
  },
  PHOTO_STUDIO_SNAP_TYPE: {
    value: 'PHOTO_STUDIO_SNAP_TYPE',
    text: 'Тип съемки фотостудии',
    color: 'blue lighten-3'
  },
  CAFE_TYPE: {
    value: 'CAFE_TYPE',
    text: 'Кафе/бар/ресторан',
    color: 'blue lighten-3'
  }
};

export {
  placeStatuses, pricingModel, orderPaymentMethods, landingTypes, subscriptionStatuses, placeTypes
}