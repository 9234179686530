import axios from 'axios'
import UserDetailsPlacesTab from './tabs/places-tab/UserDetailsPlacesTab'
import UserDetailsSubscriptionsTab from './tabs/subscriptions-tab/UserDetailsSubscriptionsTab'

export default {
  name: 'user-details',
  components: {
    UserDetailsPlacesTab,
    UserDetailsSubscriptionsTab
  },
  data: function () {
    return {
      userId: Number(this.$route.params.id),
      user: null,
      email: '',
      editing: false,
      emailSuccess: false,
      message: '',
      messageType: '',
      isFocused: false,
      clickTimeout: null,
      isDoubleClick: false
    }
  },
  computed: {
    isEmailChanged() {
      return this.email !== this.originalEmail;
    }
  },
  methods: {
    verifyUserEmail: function () {
      axios.put(`/api/v2/users/${this.userId}/emails`).then(response => {
        this.user = response.data;
      });
    },
    enableEditing(event) {
      clearTimeout(this.clickTimeout);
      this.isDoubleClick = true;
      this.email = this.user.email;
      this.originalEmail = this.user.email;
      this.editing = true;
      this.$nextTick(() => {
        this.$refs.emailInput.focus();
      });
    },
    handleClick(event) {
      if (this.editing) {
        event.preventDefault();
        return;
      }

      if (this.isDoubleClick) {
        this.isDoubleClick = false;
        return;
      }

      this.clickTimeout = setTimeout(() => {
        if (!this.isDoubleClick) {
          window.location.href = 'mailto:' + this.user.email;
        }
      }, 300);
    },
    validateEmail(email) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return emailPattern.test(email);
    },
    saveEmail() {
      if (this.validateEmail(this.email)) {
        axios.put(`/api/v2/users/${this.userId}/change-email`, { email: this.email })
            .then(response => {
              this.user.email = this.email;
              this.message = 'Електронну адресу успішно оновлено!';
              this.messageType = 'success';
              this.emailSuccess = true;
              this.editing = false;
            })
            .catch(error => {
              console.log(error);
              this.message = 'Під час оновлення електронної пошти сталася помилка.';
              this.messageType = 'error';
              this.emailSuccess = true;
            });
      } else {
        this.message = 'Невірна адреса електронної пошти.';
        this.messageType = 'error';
        this.emailSuccess = true;
      }
    },

    cancelEditing() {
      this.editing = false;
      this.emailError = false;
      this.emailSuccess = false;
    }

  },
  mounted: function () {
    axios.get(`/api/v2/users/${this.userId}`).then(response => {
      this.user = response.data;
    });
  },
}