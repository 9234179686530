import axios from 'axios';

export default {
  name: 'user-login',
  data: function () {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    login () {
      this.$validator.validateAll()
        .then(result => {
          if (!result) return;

          const data = {
            email: this.email,
            password: this.password
          };
          axios.post('/api/v2/login', data)
            .then(() => {
              const referer = this.$store.state.referer;
              if (referer) {
                this.$store.commit('resetReferer');
                this.$router.push(referer);
              } else {
                this.$router.push({name: 'MainDashboard'});
              }
            }).catch(error => {
              if (error.response.status === 401) {
                this.errors.add('password', 'Неверный логин или пароль', 'auth');
              }
          });
        });
    }
  },
  created: function () {
    this.$store.commit('setNavigation', false);
  },
  beforeDestroy: function () {
    this.$store.commit('setNavigation', true);
  }
};