var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"lg5":"","xl4":""}},[_c('v-radio-group',{attrs:{"row":"","hide-details":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('v-radio',{attrs:{"label":"Все","value":"ALL"}}),_c('v-radio',{attrs:{"label":"Новые","value":"NEW"}}),_c('v-radio',{attrs:{"label":"Approved","value":"APPROVED"}}),_c('v-radio',{attrs:{"label":"Rejected","value":"REJECTED"}})],1)],1),_c('v-spacer')],1),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-1 review-table",attrs:{"headers":_vm.headers,"items":_vm.reviews,"footer-props":_vm.footerProps,"sort-by":"timeCreated","sort-desc":true},scopedSlots:_vm._u([{key:"item.reviewerId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'UserDetails', params: { id: item.reviewerId } }}},[_vm._v(_vm._s(item.reviewerId))])]}},{key:"item.resourceId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.resourceId)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"slot":"activator","icon":"","small":"","to":{ name: 'PlaceDetails', params: { id: item.resourceId } }},slot:"activator"},on),[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-file-find")])],1)]}}],null,true)},[_c('span',[_vm._v("Детальнее")])])]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.remove(item.id)}}},on),[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Удалить")])])]}},{key:"item.stars",fn:function(ref){
var item = ref.item;
return [_c('el-rate',{attrs:{"disabled":""},model:{value:(item.stars),callback:function ($$v) {_vm.$set(item, "stars", $$v)},expression:"item.stars"}})]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(item.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.timeCreated,"DD.MM.YYYY")))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'APPROVED')?_c('v-icon',{attrs:{"color":"green lighten-1"}},[_vm._v("mdi-check")]):_vm._e(),(item.status === 'REJECTED')?_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-close")]):_vm._e(),(item.status === 'NEW')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.changeStatus('APPROVED', item.id)}}},on),[_c('v-icon',{attrs:{"color":"green lighten-1"}},[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Принять")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.changeStatus('REJECTED', item.id)}}},on),[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Отклонить")])])],1):_vm._e()]}}])},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"text-center black--text",attrs:{"value":!_vm.isLoading,"color":"blue-grey lighten-5"}},[_vm._v("Ничего не найдено")])],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }