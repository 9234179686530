import _ from 'lodash';
import axios from 'axios';

export default {
    name: 'internationalization-list',
    data: function () {
        return {
            fab: false,
            languages: [{'id': 1, 'lang': 'ru'}, {'id': 2, 'lang': 'ua'}],
            isLoading: true,
            headers: [
                {text: 'Язык', value: 'lang', sortable: true}
            ],
            options: {
                sortBy: ['language'],
                sortDesc: [false]
            },
            footerProps: {
                itemsPerPageText: 'Языков на странице',
                itemsPerPageAllText: 'Все',
                itemsPerPageOptions: [10, 25, -1]
            },
        };
    },
    methods: {
        languageClick: function(language) {
            this.$router.push({ name: 'LanguageDictionary', params: { lang: language.lang }});
        },
        sortFilter: function (items, index, isDescending) {
            const sortBy = index[0];
            return _.orderBy(items, [sortBy], [isDescending[0] ? 'desc' : 'asc']);
        },
        $_fetchLanguages: function () {
            this.isLoading = true;

            axios.get('/api/v2/language/ru')
                .then(response => {
               //     this.languages = response.data.map(language => language);
                    this.isLoading = false;
                });
        }
    },
    mounted: function () {
        this.$_fetchLanguages();
    }
};