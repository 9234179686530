import axios from 'axios';
import MainDashboardCard from './MainDashboardCard.vue';

export default {
  name: 'main-dashboard',
  components: {
    MainDashboardCard
  },
  data: function () {
    return {
      dashboardInfo: null
    }
  },
  mounted: function () {
    const vm = this;
    axios.get('/api/v2/dashboards/main')
      .then(function (response) {
        vm.dashboardInfo = response.data;
      });
  }
};