import axios from 'axios'

export default {
  name: 'url-formatting',
  data: function () {
    return {
      googleApiKey: this.$store.state.settings.googleApiKey,
      utmSource: 'letter',
      utmMedium: 'email',
      utmCampaign: 'Рассылка предложений',
      link: null,
      formattedLink: null,
      processing: false
    }
  },
  methods: {
    formatLink: function () {
      this.$validator.validateAll().then(result => {
        if (!result) return;
        this.processing = true;

        const url = new URL(this.link);
        url.searchParams.set('utm_source', this.utmSource);
        url.searchParams.set('utm_medium', this.utmMedium);
        url.searchParams.set('utm_campaign', this.utmCampaign);

        axios.post(`https://www.googleapis.com/urlshortener/v1/url?key=${this.googleApiKey}`, {
          longUrl: url.toString()
        }).then(response => {
          this.formattedLink = response.data.id;
          this.processing = false;
        }).catch(() => {
          this.processing = false;
        });
      });
    },
    copyLink: function () {
      document.getElementById('formatted-link').select();
      document.execCommand('copy');
    }
  }
};