var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-1 default-table",attrs:{"headers":_vm.headers,"items":_vm.placesList,"loading":_vm.isLoading,"hide-default-footer":true},on:{"click:row":_vm.placeClick},scopedSlots:_vm._u([{key:"item.cover",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"mt-2 mb-2"},[_c('v-img',{attrs:{"src":item.coverUrl,"height":"120px","width":"200px"}})],1)]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.placeName))]),_c('div',[_vm._v(_vm._s(item.address.district.name)+", "+_vm._s(item.address.subDistrict.name))]),_c('div',[_vm._v(_vm._s(item.address.formattedName))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.type.color,"text-color":"white","small":""}},[_vm._v(" "+_vm._s(item.type.text)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status.color,"text-color":"white","small":""}},[_vm._v(" "+_vm._s(item.status.text)+" ")])]}}])},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"text-center black--text",attrs:{"value":!_vm.isLoading,"color":"blue-grey lighten-5"}},[_vm._v("Ничего не найдено")])],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }