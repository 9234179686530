import axios from 'axios';
import _ from 'lodash';

export default {
  name: 'user-list',
  data: function () {
    return {
      fab: false,
      footerProps: {
        itemsPerPageText: 'Пользователей на странице',
        itemsPerPageOptions: [20, 50, 100]
      },
      headers: [
        {text: '№', value: 'id', align: 'left', sortable: false},
        {text: 'Имя', value: 'name', align: 'left', sortable: false},
        {text: 'E-mail', value: 'email', align: 'left', sortable: false},
        {text: 'Телефон', value: 'phone', align: 'center', sortable: false},
        {text: 'Город', value: 'city', align: 'center', sortable: false},
        {text: 'Помещений', value: 'placesAmount', align: 'center', sortable: false},
        {text: 'Impersonate', value: 'impersonate', align: 'center', sortable: false}
      ],
      options: this.$store.state.usersListPage.options || {
        sortBy: 'id',
        descending: false,
        page: 1
      },
      totalItems: 0,
      isLoading: true,
      type: this.$store.state.usersListPage.type || 'ALL',
      searchValue: this.$store.state.usersListPage.searchValue || '',
      users: []
    }
  },
  watch: {
    options: {
      handler () {
        this.$_fetchUsers(true);
      },
      deep: true
    },
    type: function () {
      this.$_fetchUsers();
    },
    searchValue: _.debounce(function () {
      this.$_fetchUsers();
    }, 300)
  },
  computed: {
    downloadEmails: function () {
      return `/api/v2/users/export?filter=${this.type}`
    }
  },
  methods: {
    $_fetchUsers: function (preservePage) {
      this.isLoading = true;
      if (!preservePage) this.options.page = 1;

      axios.get('/api/v2/users', {
        params: {
          type: this.type,
          page: this.options.page,
          size: this.options.itemsPerPage,
          searchValue: this.searchValue
        }
      })
      .then(response => {
        this.users = response.data.users;
        this.totalItems = response.data.totalUsers;
        this.isLoading = false;
        this.$_saveState();
      });
    },
    $_saveState: function () {
      const storeObj = {
        type: this.type,
        options: this.options,
        searchValue: this.searchValue
      };
      this.$store.commit('updateUserListPage', storeObj);
    }
  }
}