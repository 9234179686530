import Vue from 'vue';
import vuetify from './plugins/vuetify';
import VeeValidate, { Validator } from 'vee-validate';
import App from './App.vue';

import 'element-ui/lib/theme-chalk/index.css';

import moment from 'moment';
import ruLocale from 'vee-validate/dist/locale/ru';

import router from './router';
import store from './store';
import JSONView from 'vue-json-component';
import Element from 'element-ui'

let VueCookie = require('vue-cookie');
Vue.use(VueCookie);

moment.locale('ru');
Validator.localize('ru', ruLocale);
Vue.use(VeeValidate);

Vue.config.productionTip = false;

Vue.filter('formatDate', function (value, format) {
  return moment(value).format(format);
});

Vue.use(JSONView);

Vue.use(Element)

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
