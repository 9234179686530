<template>
	<v-app>
		<app-navigation v-show="this.$store.state.global.showNavigation"></app-navigation>
		<app-header v-show="this.$store.state.global.showNavigation"></app-header>
		<v-main :style="this.$store.state.global.showNavigation ? '' : 'padding: 0'">
			<transition name="component-fade" mode="out-in">
				<router-view></router-view>
			</transition>
		</v-main>
		<app-global-error-snack></app-global-error-snack>
	</v-app>
</template>

<script>
  import AppHeader from './components/common/header/AppHeader';
  import AppNavigation from './components/common/navigation/AppNavigation';
  import AppGlobalErrorSnack from './components/system/error/AppGlobalErrorSnack';

  export default {
    components: {
      AppHeader,
      AppNavigation,
      AppGlobalErrorSnack
    }
  };
</script>

<style lang="scss" src="./styles/common.scss"></style>