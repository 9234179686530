import axios from 'axios';

export default {
    name: 'amenity-list',
    data: function () {
        return {
            footerProps: {
                itemsPerPageText: 'Услуг на странице',
                itemsPerPageOptions: [20, 50, 100]
            },
            isLoading: true,
            headers: [
                { text: 'Имя', value: 'localizedNames', sortable: true },
                { text: 'Группа', value: 'localizedGroupNames', sortable: true },
                { text: ' Cохранить', value: 'save', sortable: false },
            ],
            options: {
                sortBy: 'timeCreated',
                descending: false,
                page: 1
            },
            amenities: [],
            type: 'STANDARD',
            snackVisible: false,
        };
    },
    watch: {
        options: {
            handler() {
                this.$_fetchAmenities(true);
            },
            deep: true
        },
        type: function () {
            this.$_fetchAmenities();
        },
    },
    methods: {
        saveAmenity: function (amenity) {
            axios.put('/api/v2/amenities', amenity)
                .then(response => {
                    let amenityIndex = this.amenities.findIndex(a => a.id === amenity.id);
                    let updatedAmenity = response.data;
                    this.amenities[amenityIndex] = updatedAmenity;
                    this.snackVisible = true;
                })
        },
        touched: function (amenity) {
            let amenityIndex = this.amenities.findIndex(a => a.id === amenity.id);
            this.amenities[amenityIndex].touched = true;
        },

        $_fetchAmenities: function () {
            this.isLoading = true;
            axios.get('/api/v2/amenities', {
                params: {
                    type: this.type,
                }
            }).then(response => {
                this.amenities = response.data;
                this.isLoading = false;
            });
        }
    },
    mounted: function () {
        this.$_fetchAmenities();
    }
};