import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import _ from 'lodash';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    global: {
      drawer: true,
      showNavigation: true,
    },
    locale: 'ru',
    availableLocales: [
      { code: 'uk', name: 'UA' },
      { code: 'ru', name: 'RU' },
    ],
    user: {
      isAuthenticated: false,
      id: null,
      name: null,
      avatarUrl: null
    },
    settings: {
      domainUrl: null,
      googleApiKey: null
    },
    properties: {},
    usersListPage: {},
    placesListPage: {},
    placeDetailsPage: {},
    landingListPage: {},
    referer: null
  },
  mutations: {
    keepReferer(state, referer) {
      state.referer = referer;
    },
    resetReferer(state) {
      state.referer = null;
    },
    toggleDrawer(state) {
      state.global.drawer = !state.global.drawer;
    },
    setNavigation(state, value) {
      state.global.showNavigation = value;
    },
    setUser(state, user) {
      state.user.isAuthenticated = true;
      _.assign(state.user, user);
    },
    setProperties(state, properties) {
      _.assign(state.properties, properties);
    },
    setSettings(state, settings) {
      _.assign(state.settings, settings);
    },
    removeUser(state) {
      state.user.isAuthenticated = false;
    },
    updateUserListPage(state, stateObj) {
      state.usersListPage = stateObj;
    },
    updatePlacesListPage(state, stateObj) {
      state.placesListPage = stateObj;
    },
    updatePlaceDetailsPage(state, stateObj) {
      state.placeDetailsPage = stateObj;
    },
    updateLandingListPage(state, stateObj) {
      state.landingListPage = stateObj;
    },
    setLocale(state, locale) {
      state.locale = locale;
    }
  },
  actions: {
    fetchUser(context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/v2/session').then(response => {
          context.commit('setUser', response.data.currentUser);
          context.commit('setProperties', response.data.properties);
          context.commit('setSettings', response.data.settings);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })
    }
  }
});

export default store;