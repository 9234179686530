var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"lg4":"","xl3":""}},[_c('v-radio-group',{attrs:{"row":"","hide-details":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-radio',{attrs:{"label":"Все","value":"ALL"}}),_c('v-radio',{attrs:{"label":"Хосты","value":"HOST"}}),_c('v-radio',{attrs:{"label":"Клиенты","value":"CLIENT"}})],1)],1),_c('v-spacer'),_c('v-flex',{attrs:{"lg4":"","xl3":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-account-search-outline","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)],1),_c('v-layout',{staticClass:"pb-12",attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-2 user-table",attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.options,"loading":_vm.isLoading,"loading-text":"Поиск пользователей","server-items-length":_vm.totalItems,"items-per-page":_vm.footerProps.itemsPerPageOptions[0],"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"50px"}},[(item.imgUrl)?_c('img',{attrs:{"src":item.imgUrl}}):_c('v-icon',{attrs:{"size":"50px","color":"accent"}},[_vm._v("mdi-face")])],1),_c('router-link',{attrs:{"to":{ name: 'UserDetails', params: { id: item.id } }}},[_vm._v(_vm._s(item.firstName))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.email))]),(item.hasBouncedEmail)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red darken-4","small":""}},on),[_vm._v("mdi-alert-circle")])]}}],null,true)},[_c('span',[_vm._v("Bounced email")])]):(item.isVerified)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":""}},on),[_vm._v("mdi-email-check")])]}}],null,true)},[_c('span',[_vm._v("Почта подтверждена")])]):_vm._e()]}},{key:"item.impersonate",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"hide-decoration",attrs:{"target":"_blank","href":("/api/v2/users/" + (item.id) + "/impersonate")}},[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1)]}}])},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"text-center black--text",attrs:{"value":!_vm.isLoading,"color":"blue-grey lighten-5"}},[_vm._v("Ничего не найдено")])],1)],2)],1)],1),_c('v-speed-dial',{attrs:{"fixed":"","bottom":"","right":"","direction":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","dark":"","fab":"","hover":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","href":_vm.downloadEmails,"color":"green"}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }