import axios from 'axios'

import AppDatePicker from '../../common/date-picker/AppDatePicker'
import { placeStatuses } from '../../../js/status-mapper'

export default {
  name: 'place-details',
  components: {
    AppDatePicker
  },
  data: function () {
    return {
      domainUrl: this.$store.state.settings.domainUrl,
      placeId: Number(this.$route.params.id),
      place: null,
      calendarBtnLoading: false
    }
  },
  methods: {
    toggleCalendar: function () {
      this.calendarBtnLoading = true;
      axios.put(`/api/v2/places/${this.placeId}/calendar`).then(response => {
        this.place.isCalendarEnabled = !this.place.isCalendarEnabled;
        this.calendarBtnLoading = false;
      }).catch(error => {
        this.calendarBtnLoading = false;
      });
    },
  },
  created: function () {
    axios.get(`/api/v2/places/${this.placeId}`).then(response => {
      this.place = response.data;
      this.place.status = placeStatuses[this.place.status];
    });
  }
}
