import axios from 'axios';

export default {
    name: 'review-list',
    data: function () {
        return {    
            footerProps: {
                itemsPerPageText: 'Отзывов на странице',
                itemsPerPageOptions: [20, 50, 100]
            },
            isLoading: true,
            headers: [
                { text: 'Поьзователь', value: 'reviewerId', sortable: true },
                { text: 'Локация', value: 'resourceId', sortable: true },
                { text: 'Оценка', value: 'stars', sortable: true, width: '160px' },
                { text: 'Отзыв', value: 'text', sortable: false },
                { text: 'Статус', value: 'status', sortable: false },
                { text: 'Действие', value: 'remove', sortable: false },
            ],
            options: {
                sortBy: 'timeCreated',
                descending: false,
                page: 1
            },
            reviews: [],
            status: 'NEW'
        };
    },
    watch: {
        options: {
            handler() {
                this.$_fetchReviews(true);
            },
            deep: true
        },
        status: function () {
            this.$_fetchReviews();
        },
    },
    methods: {

        changeStatus: function (status, id) {
            axios.put(`/api/v2/reviews/${id}?status=${status}`)
            .then(response => {
                    let reviewIndex = this.reviews.findIndex(review => review.id === id);
                    this.reviews[reviewIndex].status = status;
                })
        },

        remove: function (id) {
            if(confirm('Вы уверенны что хотите удалить отзыв?')) {
                this.isLoading = true;
                axios.delete(`/api/v2/reviews/${id}`)
                    .then(response => {
                        this.reviews = this.reviews.filter(review => review.id !== id )
                        this.isLoading = false;
                    })
            }
        },

        $_fetchReviews: function () {
            this.isLoading = true;
            axios.get('/api/v2/reviews', {
                params: {
                    status: this.status,
                }
            }).then(response => {
                    this.reviews = response.data;
                    this.isLoading = false;
                });
        }
    },
    mounted: function () {
        this.$_fetchReviews();
    }
};