import axios from 'axios'
import { mapState } from 'vuex';
import _ from 'lodash'

import { landingTypes } from '../../../js/status-mapper'
import upload from '../../../js/upload'

export default {
  name: 'landing-edit',
  data: function () {
    return {
      domainUrl: this.$store.state.settings.domainUrl,
      landingId: Number(this.$route.params.id),
      landingInfo: null,
      possibleCities: [{ value: 0, text: 'Общий'}],
      possibleLandingTypes: [],
      possibleEventTypes: [],
      possibleBadges: [],
      uploading: false,
      fab: false,
      saveSnack: false
    }
  },
  computed: {
    typeFilter: function () {
      const valueType = this.landingInfo.valueType;
      switch (valueType) {
        case landingTypes.EVENT_TYPE.value:
          return this.possibleEventTypes;
        case landingTypes.BADGE_TYPE.value:
          return this.possibleBadges;
        case landingTypes.CITY_TYPE.value:
          return [{ value: 0, text: 'Город'}];
        case landingTypes.PHOTO_STUDIO_TYPE.value:
          return [{ value: 0, text: 'Фотостудии'}];
        case landingTypes.CAFE_TYPE.value:
          return [{ value: 0, text: 'Кафе/бар/ресторан'}];
      }
    },
    ...mapState(['locale']),
    computedLocaleState() {
      return this.locale;
    }
  },
  watch: {
    selectedCities: function () {
      this.$_fetchLandings();
    },
    locale(val, oldVal) {
      this.$_fetchLanding();
    }
  },
  methods: {
    openFileExplorer: function () {
      this.$refs.fileInput.click();
    },
    $_fetchLanding: function() {
      axios.get(`/api/v2/landings/${this.landingId}`)
        .then(response => {
          const landingInfo = response.data;
          landingInfo.cityId = landingInfo.isGeneral ? 0 : landingInfo.city.id;

          this.landingInfo = landingInfo;
        });
    },
    uploadMedia: function(files) {
      this.uploading = true;
      const image = files[0];

      upload(`/api/v2/landings/${this.landingId}/medias`, image)
      .then(response => {
        this.uploading = false;
        this.landingInfo.backgroundImgUrl = response.data.url;
      })
      .catch(() => {
        this.uploading = false;
      });
    },
    saveLanding: function () {
      axios.put(`/api/v2/landings/${this.landingId}`, this.landingInfo).then(() => {
        this.saveSnack = true;
      });
    }
  },
  created: function () {
    axios.get(`/api/v2/landings/${this.landingId}`)
      .then(response => {
        const properties = this.$store.state.properties;
        properties.cities.forEach(city => {
          const cityObj = {
            value: city.id,
            text: city.name
          };
          this.possibleCities.push(cityObj);
        });

        this.possibleLandingTypes = properties.landingTypes.map(l => {
          return {
            value: l,
            text: landingTypes[l].text
          }
        });

        this.possibleEventTypes = _.flatten(Object.values(properties.eventTypes)).map(eventType => {
          return {
            value: eventType.id,
            text: eventType.name
          }
        });

        this.possibleBadges = properties.badges
          .filter(badge => badge.filterable)
          .map(badge => {
            return {
              value: badge.id,
              text: badge.name
            }
        });

        const landingInfo = response.data;
        landingInfo.cityId = landingInfo.isGeneral ? 0 : landingInfo.city.id;

        this.landingInfo = landingInfo;
      });
  }
}